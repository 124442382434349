<template>
    <section>
        <ValidationObserver ref="formCupo">
            <div class="row mx-0">
                <div class="col-8 px-3 py-3 bg-white br-b-8">
                    <p class="text-general br-5 f-600 mb-3 ml-3">
                        Datos Generales
                    </p>
                    <div class="row mx-0">
                        <ValidationProvider v-slot="{errors}" rules="required" name="nombre" tag="div" class="col-4">
                            <!-- <div class="col-4"> -->
                            <p class="text-general f-15 f-500 pl-3">Nombre del cupón</p>
                            <el-input v-model="form.nombre" class="w-100" size="small" show-word-limit maxlength="40" @change="updateCampos" />
                            <!-- </div> -->
                            <p class="text-danger w-100 f-11 pl-2"> {{ errors[0] }} </p>
                        </ValidationProvider>
                        <ValidationProvider v-slot="{errors}" rules="required" name="codigo" tag="div" class="col-3">
                            <!-- <div class="col-3"> -->
                            <p class="text-general f-15 f-500 pl-3">Código</p>
                            <el-input v-model="form.codigo" class="w-100" size="small" show-word-limit maxlength="25" @change="updateCampos" />
                            <!-- </div> -->
                            <p class="text-danger f-11 pl-2"> {{ errors[0] }}</p>
                        </ValidationProvider>
                        <div class="col-7 mt-3">
                            <p class="text-general f-15 f-500 pl-3">Tipo de Cupón</p>
                            <div class="d-middle">
                                <div class="col-auto d-middle">
                                    <div class="d-middle cr-pointer br-10 py-2">
                                        <input v-model="form.listado" :value="1" type="radio" class="option-input radio black" @change="updateCampos" />
                                        Listado
                                    </div>
                                </div>
                                <div class="col-auto d-middle">
                                    <div class="d-middle cr-pointer br-10  py-2">
                                        <input v-model="form.listado" :value="2" type="radio" class="option-input radio black" @change="updateCampos" />
                                        Digitado
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ValidationProvider v-slot="{errors}" rules="required" name="mensaje cupon" tag="div" class="col-12 my-2">
                            <!-- <div class="col-7 my-3"> -->
                            <p class="pl-3 text-general f-15 f-500">
                                Mensaje del cupón
                            </p>
                            <el-input v-model="form.descripcion" size="small" type="textarea" :rows="4" class="w-100" show-word-limit maxlength="500" @change="updateCampos" />
                            <!-- </div> -->
                            <p class="text-danger f-11 pl-3">{{ errors[0] }} </p>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
            <div v-show="crear" class="row mx-0 px-5 mb-3 mt-3">
                <div class="col-8 d-flex px-0 justify-content-end">
                    <div class="bg-general ml-auto text-white f-14 br-8 py-1 px-4 cr-pointer" @click="accionContinuar">
                        Continuar
                    </div>
                    <div class="col-auto px-4" />
                </div>
            </div>
        </ValidationObserver>
    </section>
</template>
<script>
import Cupones from '~/services/cupones/cupones'
import {mapGetters} from 'vuex'
export default {
    props:{
        crear:{
            type: Boolean,
            default: false
        },
        tipo:{
            //1=>inmediato 2 =>futuro Padre 3=> futuro hijo
            type:Number,
            default:1
        },
        clientes:{
            type:Number,
            default:1
        }
    },
    data(){
        return {
            todosClientes:1,
            form:{
                nombre:'',
                codigo:'',
                descripcion:'',
                listado:1
            }
        }
    },
    computed:{
        ...mapGetters({
            datosBasicos: 'cupones/cupones/datosBasicos',
            idCarpeta: 'cupones/cupones/idCarpeta',
        }),
        id_cupon(){
            return Number(this.$route.params.id_cupon);
        }
    },
    watch:{

    },
    destroyed(){
        this.$store.commit('cupones/cupones/set_datos_basicos',{})
    },
    mounted(){
        if(this.crear && this.idCarpeta === null){
            this.$router.push({name: 'admin.cupones.clientes.lista'})
            return
        }
        if(this.id_cupon){
            this.initDatosBasicos()
        }
    },
    methods:{
        accionContinuar(){
            if(this.crear){
                this.crearCupon()
            }
        },
        async crearCupon(){
            try {
                const valid = await this.$refs.formCupo.validate();
                if(!valid){
                    this.notificacion('', 'campos obligatorios', 'warning')
                    return false
                }
                this.form.tipo = this.tipo
                this.form.aplica_usuarios = this.clientes
                this.form.id_carpeta = this.idCarpeta
                const {data} = await Cupones.crearCupon(this.form)

                let id_cupon = data.cupon.id
                this.$router.push({name:'admin.cupones.editar',params:{id_cupon:id_cupon}})


            } catch (e){
                this.error_catch(e)
            }
        },
        initDatosBasicos(){
            let that = this
            if(_.isEmpty(this.datosBasicos)){
                setTimeout(function(){
                    that.initDatosBasicos()
                }, 800);
            }
            this.form.nombre = this.datosBasicos.nombre
            this.form.codigo = this.datosBasicos.codigo
            this.form.descripcion = this.datosBasicos.descripcion
            this.form.listado = this.datosBasicos.listado
        },
        async updateCampos(){ // esta accion es solo para editar
            if(this.crear) return
            try {

                const {data} = await Cupones.editarCupon(this.form,this.id_cupon)

            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.active-button{
    background-color: var(--color-general);
    color: #ffffff;
    box-shadow: 0px 3px 6px #00000029;
}
.inactive-button{
    background-color: #F5F5F5;
    color: #707070;
    box-shadow: 0px 3px 6px #00000029;
}
</style>
